import { VisitorContextType } from "src/providers/VisitorContext/types";
import handleWebAppRedirection from "./handleWebAppRedirection";

const OLD_SIGNUP_PATTERN = /(^\/inscription\/)|(shine\.fr\/inscription)/;
const NEW_SIGNUP_PATTERN = /\/app\.shine\.fr\/register/;

/**
 * Does this URL match the pattern of the new previous /inscription/ route?
 */
export const isSignupURL = (url?: string) => !!url && (OLD_SIGNUP_PATTERN.test(url) || NEW_SIGNUP_PATTERN.test(url));

/**
 * Provide this function with the visitorContext.
 * The resulting function will patch URLs targeting the signup flow with necessary query parameters.
 */
const patchSignupURL = (visitorContext: VisitorContextType, destination?: string) => {
  const { referrerCode } = visitorContext;
  if (referrerCode) {
    return `/referral/${referrerCode}`;
  }

  return handleWebAppRedirection(visitorContext, destination);
};

export default patchSignupURL;
