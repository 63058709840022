import {
  Stack,
  Text,
  Grid,
  VStack,
  HStack,
  Wrap,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainer, IconBox } from "src/atoms";
import Renderer from "src/lib/renderer";
import { Element } from "@prismicio/react";
import { hyperlink } from "src/lib/renderer/elements/hyperlink";
import { isValidElement } from "react";
import HeaderMedia from "./components/HeaderMedia";
import { SvgOrImg } from "src/components/SvgOrImg";
import { MAX_NON_CENTERED_IMAGE_WIDTH } from "./constants";

export const NAVBAR_HEIGHT = 68;
export const NAVBAR_HEIGHT_MOBILE = 49;

export const PAGE_HEADER_OVERLAPS = {
  overlap: 80,
  no_overlap: undefined,
};

const Header: FC<Gatsby.PageHeaderFragment> = ({ primary, items }) => {
  if (!primary) throw Error();

  const {
    is_centered,
    buttons,
    content,
    media,
    media_mobile,
    hide_media_on_mobile,
    media_video,
  } = primary;

  //all breakpoint needs to be specified.
  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  return (
    <BlockContainer
      textAlign={{ base: "left", md: "left" }}
      paddingTop={{ base: "space-16" }}
    >
      <Grid
        gridTemplateColumns={{
          base: "1fr",
          md: is_centered ? "1fr" : "1fr 1fr",
          xl: is_centered ? "1fr" : `1fr ${MAX_NON_CENTERED_IMAGE_WIDTH}px`,
        }}
        gap={{
          base: "space-16",
          md: "space-40",
          "2xl": is_centered ? "space-40" : "space-80",
        }}
        alignItems="stretch"
        bg={{ md: "yellow-0" }}
        borderRadius="lg"
        paddingLeft={{ md: "space-40", lg: "space-80" }}
        paddingRight={{
          md: is_centered ? "space-80" : "space-40",
          lg: "space-80",
        }}
        paddingY={{ md: 0 }}
        position="relative"
        marginBottom={{ md: "space-40" }}
        w="full"
      >
        <VStack
          alignItems={{
            base: "center",
            md: is_centered ? "center" : "start",
          }}
          borderRadius="lg"
          justifyContent="center"
          textAlign={{
            base: "left",
            md: is_centered ? "center" : "start",
          }}
          align="center"
          maxW="730px"
          spacing={{ base: "space-16", md: "space-40" }}
          bg={{ base: "yellow-0", md: "transparent" }}
          paddingX={{ base: "space-24", md: 0 }}
          paddingY={{ base: "space-24", lg: "58px" }}
          minW="full"
        >
          <VStack
            spacing={{ base: "space-16", md: "space-24" }}
            align="flex-start"
            sx={{
              "h1, h2, h3": {
                marginBottom: 0,
              },
            }}
          >
            <Renderer field={content} />
          </VStack>

          <VStack
            spacing={{ base: "space-16", md: 0 }}
            w="full"
            alignItems={{ md: "start" }}
          >
            <Stack
              wrap="wrap"
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "center", md: "center" }}
              textAlign={{
                base: "center",
                md: is_centered ? "center" : "left",
              }}
              spacing={{ base: "space-8", md: 0 }}
              sx={{
                p: {
                  w: { base: "full", md: "auto" },
                  margin: { base: 0 },
                  marginBottom: { md: "24px !important" }, //important is required to override chakra stack default css
                  marginRight: { md: "24px !important" }, //important is required to override chakra stack default css
                },
                ".btn-primary, .btn-secondary": {
                  w: { base: "full", md: "auto" },
                },
              }}
              mx={is_centered ? "auto" : undefined}
            >
              <Renderer
                field={buttons}
                overrides={{
                  [Element.hyperlink]: (args) => {
                    const isSecondaryButton = args?.children.some(
                      (child) =>
                        isValidElement(child) &&
                        child.props.className === "button-secondary",
                    );

                    return hyperlink({
                      ...args,
                      overrideProps: isSecondaryButton
                        ? {
                            variant: "lightGrey",
                          }
                        : {},
                    });
                  },
                }}
              />
            </Stack>

            <Wrap
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "space-4", md: "space-24" }}
              alignSelf={{
                base: "flex-start",
                md: is_centered ? "center" : "flex-start",
              }}
            >
              {items?.map((item) => {
                if (!item) return null;

                return (
                  <HStack
                    key={item.label || ""}
                    spacing={{ base: "space-8", md: 0 }}
                  >
                    {item?.icon?.document ? (
                      <IconBox
                        bg={{ base: "yellow-2", md: "transparent" }}
                        borderRadius="md"
                        w={{ base: "28px", md: "16px" }}
                        h={{ base: "28px", md: "16px" }}
                        mr={{ md: "space-8" }}
                      >
                        <SvgOrImg
                          imageFile={item.icon?.document?.data?.image_file}
                          size={16}
                        />
                      </IconBox>
                    ) : null}
                    <Text as="div" variant="body-small">
                      {item.label}
                    </Text>
                  </HStack>
                );
              })}
            </Wrap>
          </VStack>
        </VStack>

        {media?.document ? (
          <HeaderMedia
            is_centered={is_centered!}
            hide_media_on_mobile={hide_media_on_mobile!}
            media_video={media_video}
            media={
              isMobile ? (media_mobile.document ? media_mobile : media) : media
            }
          />
        ) : null}
      </Grid>
    </BlockContainer>
  );
};

export default Header;
