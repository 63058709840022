import { MAX_MOBILE_IMAGE_WIDTH } from "./constants";

const getSizes = (isCentered: boolean) => {
  const maxImgWidth = isCentered ? '600px' : '582px';
  const sizes = `(min-width: 60em) ${maxImgWidth}, (min-width: 30em) ${MAX_MOBILE_IMAGE_WIDTH}px, 100vw`;

  return sizes;
};

export { getSizes };
